const options = [
  {
    label: 'Admin',
    key: ['admin.users', 'admin.clients', 'admin.configuration', 'admin.generate_pdf'],
    hasSubMenu: true,
    subMenuOptions: [
      {
        label: 'Usuarios',
        labelNav: 'Administrador - Usuarios',
        key: ['admin.users'],
        link: 'admin.users'
      },
      {
        label: 'Clientes',
        labelNav: 'Administrador - Clientes',
        key: ['admin.clients'],
        link: 'admin.clients'
      },
      {
        label: 'Configuración',
        labelNav: 'Administrador - Configuración',
        key: ['admin.configuration'],
        link: 'admin.configuration'
      },
      {
        label: 'Generar PDF',
        labelNav: 'Administrador - Generar PDF',
        key: ['admin.generate_pdf'],
        link: 'admin.generate_pdf'
      }
    ]
  },
  {
    label: 'Catálogo',
    key: ['admin.products_list', 'admin.categories_list'],
    hasSubMenu: true,
    subMenuOptions: [
      {
        label: 'Productos',
        labelNav: 'Catálogo - Productos',
        key: ['admin.products_list'],
        link: 'admin.products_list'
      },
      {
        label: 'Categorías',
        labelNav: 'Catálogo - Categorías',
        key: ['admin.categories_list'],
        link: 'admin.categories_list'
      }
    ]
  },
  {
    label: 'Convenio',
    key: ['admin.agreement_orders_list', 'admin.agreement_products_list'],
    hasSubMenu: true,
    subMenuOptions: [
      {
        label: 'Órdenes',
        labelNav: 'Convenio - Órdenes',
        key: ['admin.agreement_orders_list'],
        link: 'admin.agreement_orders_list'
      },
      {
        label: 'Productos',
        labelNav: 'Convenio - Productos',
        key: ['admin.agreement_products_list'],
        link: 'admin.agreement_products_list'
      }
    ]
  },
  {
    label: 'Ventas',
    key: ['admin.orders', 'admin.orders_list', 'admin.quoations_list', 'admin.reports'],
    hasSubMenu: true,
    subMenuOptions: [
      {
        label: 'Guardados',
        labelNav: 'Ventas - Guardados',
        key: ['admin.quoations_list'],
        link: 'admin.quoations_list'
      },
      {
        label: 'Órdenes',
        labelNav: 'Ventas - Órdenes',
        key: ['admin.orders_list'],
        link: 'admin.orders_list'
      },
      {
        label: '',
        labelNav: 'Ventas - Detalle de Orden',
        key: ['admin.order_detail'],
        link: 'admin.order_detail'
      },
      {
        label: 'Informes',
        labelNav: 'Ventas - Informes',
        userTypes: ['superadmin'],
        key: ['admin.reports'],
        link: 'admin.reports'
      },
      {
        label: 'Callbook',
        labelNav: 'Ventas - Callbook',
        key: ['admin.orders'],
        link: 'admin.orders'
      }
    ]
  },
  {
    label: 'Clientes',
    key: ['admin.client_account', 'admin.client_active'],
    hasSubMenu: true,
    subMenuOptions: [
      {
        label: 'Información',
        labelNav: 'Clientes - Información',
        key: ['admin.client_account'],
        link: 'admin.client_account'
      },
      {
        label: 'Activaciones',
        labelNav: 'Clientes - Activaciones',
        key: ['admin.client_active'],
        link: 'admin.client_active'
      }
    ]
  },
  {
    label: 'Tienda',
    key: ['home'],
    hasSubMenu: false,
    link: 'home'
  },
]

export default options
