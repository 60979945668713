import { defineStore } from 'pinia'
import { reactive } from 'vue'
import { useNotificationStore } from '@/stores/notification'

export const useShoppingStore = defineStore('shop', {
  state: () => ({
    cart: reactive([]),
    isVisible: false,
    userBalance: reactive(0),
    hasUserBalanceLogic: false
  }),
  getters: {
    isThisProductInCart: (state) => (id) => {
      return state.cart.some((cartItem) => cartItem.item.id === id)
    },
    getAmountByID: (state) => (id) => {
      const item = state.cart.find((cartItem) => cartItem.item.id === id)
      return item ? item.amount : 0
    },
    getBalanceLogicState() {
      return this.hasUserBalanceLogic
    },
    getUserBalance(state) {
      return state.userBalance
    },
    getUserBalanceMinusTotal(state) {
      return state.userBalance - state.cartTotalPrice
    },
    cartIsVisible(state) {
      return state.isVisible
    },
    cartTotalPriceIsLessThan1500(state) {
      return state.cartTotalPrice < 1500
    },
    cartContent() {
      return this.cart
    },
    cartTotalPrice() {
      let total = 0
      if (this.cart.length > 0) {
        this.cart.forEach((cartItem) => {
          total += cartItem.item.price_discount * cartItem.amount
        })
      }
      return total
    },
    cartTotalDiscount() {
      let total = 0
      if (this.cart.length > 0) {
        this.cart.forEach((cartItem) => {
          const price = cartItem.item.price
          const discount = price - cartItem.item.price_discount
          const totalDiscount = discount * cartItem.amount
          total += totalDiscount
        })
      }
      return total
    },
    cartSubTotalPrice() {
      let subtotal = 0
      let price = 0
      if (this.cart.length > 0) {
        this.cart.forEach((cartItem) => {
          price = cartItem.item.price
          const withoutDiscount = price * cartItem.amount
          subtotal += withoutDiscount
        })
      }
      return subtotal
    },
    cartTotalItems() {
      return this.cart.length
    },
    cartProductsPayload(state) {
      return state.cart.map((cartItem) => ({
        id: cartItem.item.id,
        quantity: cartItem.amount
      }))
    },
    isEmptyCart(state) {
      return state.cart.length === 0
    }
  },
  actions: {
    updateNotBalanceLogic() {
      this.hasUserBalanceLogic = true
    },
    updateUserBalance(newBalance) {
      this.userBalance = newBalance
    },
    showCart() {
      this.isVisible = true
    },
    hideCart() {
      this.isVisible = false
    },
    updateCartVisibility(visibility) {
      this.isVisible = visibility
    },
    emptyCart() {
      this.cart = []
    },
    addItem(item, amount) {
      if (amount === 0) return
      if (item.stock !== null && amount > item.stock) {
        this.showErrorMessage(`Stock insuficiente. Solo hay ${item.stock} disponibles.`)
        return
      }
      const existingItem = this.cart.find((cartItem) => cartItem.item.id === item.id)
      if (existingItem) {
        if (item.stock !== null && existingItem.amount + amount > item.stock) {
          this.showErrorMessage(`Stock insuficiente. Solo hay ${item.stock} disponibles.`)
          return
        }
        existingItem.amount += amount
      } else {
        this.cart.push({ item, amount })
      }
      this.showSuccessMessage('Producto agregado')
    },
    addSingleItem(item, amount) {
      const existingItem = this.cart.find((cartItem) => cartItem.item.id === item.id)
      if (existingItem) {
        this.showRepeatedItemMessage(item.title, item.code)
        return
      }
      this.cart.push({ item, amount })
    },
    deleteItem(index) {
      this.cart.splice(index, 1)
      this.showSuccessMessage('Producto eliminado')
    },
    changeAmount(id, amount) {
      const item = this.cart.find((cartItem) => cartItem.item.id === id)
      if (item) {
        if (item.item.stock !== null && amount > item.item.stock) {
          this.showErrorMessage(`Stock insuficiente. Solo hay ${item.item.stock} disponibles.`)
          item.amount = item.item.stock
          return
        }
        item.amount = amount
        this.showSuccessMessage('Producto actualizado')
      }
    },
    changeAmountWithoutNotification(id, amount) {
      const item = this.cart.find((cartItem) => cartItem.item.id === id)
      if (item) {
        if (item.item.stock !== null && amount > item.item.stock) {
          item.amount = item.item.stock
          return
        }
        item.amount = amount
      }
    },
    addMultipleItems(items) {
      items.forEach((item) => {
        this.addSingleItem(item, 1)
      })
      this.showSuccessMessage('Productos agregados')
    },
    labelAvailableStocks(stock) {
      return stock > 1 ? 'disponibles' : 'disponible'
    },
    updateStocks(items) {
      items.forEach((item) => {
        const product = this.cart.find((cartItem) => cartItem.item.id === item.id)
        if (product) {
          if (item.stock !== null && item.stock > 0 && product.amount > item.stock) {
            this.showErrorMessage(
              `Stock insuficiente <b>${product.item.code}-${product.item.title}</b>. Solo hay <b>${item.stock}</b> ${this.labelAvailableStocks(item.stock)}. Se actualizará la cantidad.`,
              true
            )
            product.amount = item.stock
            product.item.stock = item.stock
          }
          if (item.stock === 0) {
            this.showErrorMessage(
              `El producto <b>${product.item.code}-${product.item.title}</b> se ha agotado. Se eliminará del carrito.`,
              true
            )
            this.deleteItem(this.cart.indexOf(product))
          }
        }
      })
    },
    addOne(id) {
      const item = this.cart.find((cartItem) => cartItem.item.id === id)
      if (item) {
        if (item.item.stock !== null && item.amount + 1 > item.item.stock) {
          this.showErrorMessage(
            `Stock insuficiente. Solo hay ${item.item.stock} ${this.labelAvailableStocks(item.item.stock)}.`
          )
          item.amount = item.item.stock
          return
        }
        item.amount += 1
        this.showSuccessMessage('Producto actualizado')
      }
    },
    subtractOne(id) {
      const item = this.cart.find((cartItem) => cartItem.item.id === id)
      if (item && item.amount > 0) {
        item.amount -= 1
        this.showSuccessMessage('Producto actualizado')
      }
      if (item.amount === 0) {
        this.deleteItem(this.cart.indexOf(item))
      }
    },
    checkAmount(id) {
      const product = this.cart.find((cartItem) => cartItem.item.id === id)
      return product ? product.amount : 0
    },
    showRepeatedItemMessage(title, code) {
      const notificationStore = useNotificationStore()
      notificationStore.setAlerts({
        permanent: true,
        status: 'warning',
        message: `<p class="fw-semibold mb-1">Producto repetido. Omitido</p>
        <small>El producto <span class="fw-semibold">${code}-${title}</span> ya se encuentra en el carrito, se <span class="fw-semibold">omitirá</span>.</small>`
      })
    },
    showSuccessMessage(message) {
      const notificationStore = useNotificationStore()
      notificationStore.setAlerts({
        status: 'success',
        icon: ['fa', 'circle-check'],
        message: `${message}`
      })
    },
    showErrorMessage(message, permanent = false) {
      const notificationStore = useNotificationStore()
      notificationStore.setAlerts({
        status: 'danger',
        icon: ['fa', 'circle-xmark'],
        message: `${message}`,
        permanent
      })
    }
  },
  persist: true
})
